<template>
  <v-card class="elevation-1" :loading="formLoading" :disabled="formLoading">
    <v-card-text>
      <p class="mb-4 text-5">
        <v-icon>{{icon.info}}</v-icon> Select parameter then click generate.</p>
      <v-form>

        <!-- View As -->
        <v-radio-group
          v-model="viewAs"
          row
          class="pt-0 mt-0"
        >
          <v-radio
            label="Table View"
            value="table-view"
          ></v-radio>
          <v-radio
            label="PDF"
            value="pdf"
          ></v-radio>
          <v-radio
            label="Excel"
            value="excel"
          ></v-radio>
        </v-radio-group>

        <!-- status -->
        <v-radio-group
          v-model="status"
          row
          class="pt-0 mt-0"
        >
          <v-radio
            label="Active"
            value="active"
          ></v-radio>
          <v-radio
            label="Separated"
            value="separated"
          ></v-radio>
        </v-radio-group>

        <!-- summary or detailed -->
        <v-radio-group
          v-model="groupType"
          row
          class="pt-0 mt-0"
        >
          <v-radio
            label="Detailed"
            value="detailed"
          ></v-radio>
          <v-radio
            label="Summary"
            value="summary"
          ></v-radio>
        </v-radio-group>

        <template v-if="groupType==='summary'">
          <!-- date -->
          <v-dialog
            ref="dialog"
            v-model="modalDate"
            :return-value.sync="dateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="To Date"
                outlined
                dense
                placeholder="To Date"
                v-bind="attrs"
                v-on="on"
                autocomplete="off"
                :append-icon="icon.calendar"
                :class="formErrorFields.dateErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.dateErrorMessage"

                @click="formError().remove('date', formErrorFields)"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable>
              <v-btn text color="primary" @click="modalDate=false">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalDate=false">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </template>

        <template v-else>
          <!-- group -->
          <v-autocomplete
            v-model="group"
            outlined
            dense
            clearable
            label="Division Group"
            item-value="id"
            item-text="title"
            :items="groups"
            :loading="groupLoading"
            :class="formErrorFields.groupErrorMessage.length > 0 ? 'mb-3' : ''"
            :error-messages="formErrorFields.groupErrorMessage"
            @change="formError().remove('group', formErrorFields)"
          ></v-autocomplete>

          <!-- by -->
          <v-autocomplete
            v-model="by"
            :items="['Department', 'Outlet / Location']"
            outlined
            dense
            clearable
            label="Group By"
            :class="formErrorFields.groupErrorMessage.length > 0 ? 'mb-3' : ''"
            :error-messages="formErrorFields.byErrorMessage"
            @keydown="formError().remove('by', formErrorFields)"
          ></v-autocomplete>

          <!-- bank -->
          <v-autocomplete
            v-model="bank"
            outlined
            dense
            clearable
            label="Bank"
            item-value="id"
            item-text="name"
            :items="banks"
            :loading="bankLoading"
            :class="formErrorFields.bankErrorMessage.length > 0 ? 'mb-3' : ''"
            :error-messages="formErrorFields.bankErrorMessage"
            @change="formError().remove('bank', formErrorFields)"
          ></v-autocomplete>

          <!-- from date -->
          <v-dialog
            ref="dialog"
            v-model="modalFromDate"
            :return-value.sync="fromDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                label="From Date"
                outlined
                dense
                placeholder="From Date"
                v-bind="attrs"
                v-on="on"
                autocomplete="off"
                :append-icon="icon.calendar"
                :class="formErrorFields.fromDateErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.fromDateErrorMessage"

                @click="formError().remove('fromDate', formErrorFields)"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fromDate" scrollable>
              <v-btn text color="primary" @click="modalFromDate=false">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalFromDate=false">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <!-- to date -->
          <v-dialog
            ref="dialog"
            v-model="modalToDate"
            :return-value.sync="toDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                label="To Date"
                outlined
                dense
                placeholder="To Date"
                v-bind="attrs"
                v-on="on"
                autocomplete="off"
                :append-icon="icon.calendar"
                :class="formErrorFields.toDateErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.toDateErrorMessage"

                @click="formError().remove('toDate', formErrorFields)"
              ></v-text-field>
            </template>
            <v-date-picker v-model="toDate" scrollable>
              <v-btn text color="primary" @click="modalToDate=false">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalToDate=false">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </template>

        <v-btn
          color="primary"
          :loading="loading"
          @click="generate">
          Generate
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          @click="reset"
        >
          Reset
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      :color="messageStatus"
      @close="message=''"
    >
    </snackbar>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCalendarOutline, mdiInformationOutline } from '@mdi/js'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import get from '@/composables/get'
import blob from '@/composables/blob'
import fnx from '@/functions/fn'

export default {
  components: {
    snackbar,
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const icon = { calendar: mdiCalendarOutline, info: mdiInformationOutline }
    const fromDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const fromDate = ref('')
    const modalFromDate = ref(null)
    const toDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const toDate = ref('')
    const modalToDate = ref(null)
    const dateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const date = ref('')
    const modalDate = ref(null)
    const viewAs = ref('table-view')
    const status = ref('active')
    const groupType = ref('detailed')
    const group = ref('')
    const groups = ref([])
    const groupLoading = ref(false)
    const bank = ref('')
    const banks = ref([])
    const bankLoading = ref(false)
    const by = ref('')
    const formLoading = ref(false)
    const formErrorFields = ref({
      fromDateErrorMessage: '',
      toDateErrorMessage: '',
      statusErrorMessage: '',
      groupErrorMessage: '',
      byErrorMessage: '',
      bankErrorMessage: '',
      dateErrorMessage: '',
    })
    const message = ref('')
    const messageStatus = ref('')
    const error = ref('')
    const reportData = ref(null)

    const generate = async () => {
      const formData = groupType.value === 'detailed' ? {
        status: status.value,
        groupType: groupType.value,
        group: group.value,
        by: by.value,
        bank: bank.value,
        fromDate: fromDate.value,
        toDate: toDate.value,
        viewAs: viewAs.value,
        date: date.value,
      } : {
        groupType: groupType.value,
        date: date.value,
        viewAs: viewAs.value,
      }

      if (viewAs.value === 'table-view') {
        emit('sendFormData', formData)
      } else {
        let mimetype = ''
        if (viewAs.value === 'pdf') {
          mimetype = 'application/pdf'
        } else {
          mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }

        const uri = groupType.value === 'detailed'
          ? `${process.env.VUE_APP_URI}/api/payroll/reports/pay-register-special`
          : `${process.env.VUE_APP_URI}/api/payroll/reports/pay-register-special-summary`
        await blob(`${uri}/${fnx.base64_encode(formData)}`, reportData, formLoading, error)
        if (error.value.data !== undefined) {
          message.value = error.value.data.message
          messageStatus.value = error.value.status
        } else {
          console.log(reportData.value)
          const url = window.URL.createObjectURL(new Blob([reportData.value], { type: mimetype }))
          window.open(url, '_blank')
        }
      }
    }

    watch(() => groupType.value, e => {
      emit('groupType', e)
    })

    const reset = () => {
      viewAs.value = 'table-view'
      status.value = 'active'
      groupType.value = 'detailed'
      group.value = ''
      by.value = ''
      bank.value = ''
      fromDate.value = ''
      toDate.value = ''
      date.value = ''
    }

    get(`${process.env.VUE_APP_URI}/api/payroll/scheduler/groups`, groups, groupLoading)
    get(`${process.env.VUE_APP_URI}/api/payroll/banks`, banks, bankLoading)

    return {
      date,
      modalDate,
      dateValue,
      fromDate,
      modalFromDate,
      fromDateValue,
      toDate,
      modalToDate,
      toDateValue,
      viewAs,
      status,
      groupType,
      group,
      groups,
      groupLoading,
      by,
      bank,
      banks,
      bankLoading,
      icon,
      formLoading,
      reset,
      generate,
      loading,
      formErrorFields,
      formError,
      message,
      messageStatus,
    }
  },

  watch: {
    // data(e) {
    //   // console.log(e)
    // },
  },
}
</script>
